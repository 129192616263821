<template>
  <div :class="is_study_information != 1? 'commodity_index':''">
    <div class="img_title" v-if="is_study_information != 1">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">商品指数</span>
      </div>
      <img style="width: 100%; height: 100%"
           src="../../assets/img/title_1.jpg"
           alt="" />
    </div>
    <div class="data_centre">
      <div class="commodity_index_box">
        <div class="left-bg clearfix">
          <div class="l-pd">
            <h5>恒银期货大类商品指数</h5>
          </div>
          <div class="rind-bor2"></div>
          <div class="commodity_index_b">
            <div class="left2">
              <dl>
                <dt>
                  <div class="left3">指数名称</div>
                  <div class="left33">最新点位</div>
                  <div class="left333">涨跌幅</div>
                </dt>
                <div v-for="(item, index) in dataList1"
                     :key="item.id"
                     @click="handle_index(item, index)">
                  <div class="clear"></div>
                  <div class="rind-bor3"></div>
                  <dd :class="index == idx ? 'dd-active index_line' : 'index_line'">
                    <div class="left3">{{ item.name }}</div>
                    <div :class="
                      is_(item) ? 'left4 green' : 'left4 red'
                    ">
                      {{ item.lastIndex?item.lastIndex.value.toFixed(2):'' }}
                    </div>
                    <div :class="
                      is_(item)
                        ? 'left5 green'
                        : 'left5 red'
                    ">
                      {{
                      is_(item)
                        ? item.lastIndex?item.lastIndex.per:''
                        : item.lastIndex?"+" + item.lastIndex.per:''
                    }}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="right2">
              <div style="height: 75px">
                <h2 style="font-size: 22px; color: #333">
                  {{ echaerData.name }}
                </h2>
                <div class="clearfix">
                  <h3 :class="
                    is_(echaerData)
                      ? 'red_1 green'
                      : 'red_1 red'
                  ">
                    {{ echaerData.lastIndex?echaerData.lastIndex.value.toFixed(2):'' }}
                  </h3>
                  <h4 :class="
                    is_(echaerData)
                      ? 'red_2 green'
                      : 'red_2 red'
                  ">
                    {{
                    is_(echaerData)
                      ? echaerData.lastIndex?echaerData.lastIndex.per:''
                      : echaerData.lastIndex?"+" + echaerData.lastIndex.per:''
                  }}
                  </h4>
                  <p><a @click="handle_look_big(1)">查看大图 ></a></p>
                </div>
              </div>
              <div class="right_echar"
                   ref="echar_1"></div>
            </div>
          </div>
        </div>
        <div class="left-bg clearfix">
          <div class="l-pd">
            <h5>恒银期货小类商品指数</h5>
          </div>
          <div class="rind-bor2"></div>
          <div class="commodity_index_b">
            <div class="left2">
              <dl>
                <dt>
                  <div class="left3">指数名称</div>
                  <div class="left33">最新点位</div>
                  <div class="left333">涨跌幅</div>
                </dt>
                <div v-for="(item, index) in dataList2"
                     :key="item.id"
                     @click="handle_index1(item, index)">
                  <div class="clear"></div>
                  <div class="rind-bor3"></div>
                  <dd :class="index == idx1 ? 'dd-active index_line' : 'index_line'">
                    <div class="left3">{{ item.name }}</div>
                    <div :class="
                      is_(item)
                        ? 'left4 green'
                        : 'left4 red'
                    ">
                      {{ item.lastIndex?item.lastIndex.value.toFixed(2):'' }}
                    </div>
                    <div :class="
                      is_(item)
                        ? 'left5 green'
                        : 'left5 red'
                    ">
                      {{
                      is_(item)
                        ? item.lastIndex?item.lastIndex.per:''
                        : item.lastIndex?"+" + item.lastIndex.per:''
                    }}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="right2">
              <div style="height: 75px">
                <h2 style="font-size: 22px; color: #333">
                  {{ echaerData1.name }}
                </h2>
                <div class="clearfix">
                  <h3 :class="
                    is_(echaerData1)
                      ? 'red_1 green'
                      : 'red_1 red'
                  ">
                    {{ echaerData1.lastIndex?echaerData1.lastIndex.value.toFixed(2):'' }}
                  </h3>
                  <h4 :class="
                    is_(echaerData1)
                      ? 'red_2 green'
                      : 'red_2 red'
                  ">
                    {{
                    is_(echaerData1)
                      ? echaerData1.lastIndex?echaerData1.lastIndex.per:''
                      : echaerData1.lastIndex?"+" + echaerData1.lastIndex.per:''
                  }}
                  </h4>
                  <p><a @click="handle_look_big(2)">查看大图 ></a></p>
                </div>
              </div>
              <div class="right_echar"
                   ref="echar_2"></div>
            </div>
          </div>
        </div>
        <div class="left-bg clearfix">
          <div class="l-pd">
            <h5>恒银期货金融期货类指数</h5>
          </div>
          <div class="rind-bor2"></div>
          <div class="commodity_index_b">
            <div class="left2">
              <dl>
                <dt>
                  <div class="left3">指数名称</div>
                  <div class="left33">最新点位</div>
                  <div class="left333">涨跌幅</div>
                </dt>
                <div v-for="(item, index) in dataList3"
                     :key="item.id"
                     @click="handle_index2(item, index)">
                  <div class="clear"></div>
                  <div class="rind-bor3"></div>
                  <dd :class="index == idx2 ? 'dd-active index_line' : 'index_line'">
                    <div class="left3">{{ item.name }}</div>
                    <div :class="
                      is_(item)
                        ? 'left4 green'
                        : 'left4 red'
                    ">
                      {{ item.lastIndex?item.lastIndex.value.toFixed(2):'' }}
                    </div>
                    <div :class="
                      is_(item)
                        ? 'left5 green'
                        : 'left5 red'
                    ">
                      {{
                      is_(item)
                        ? item.lastIndex?item.lastIndex.per:''
                        : item.lastIndex?"+" + item.lastIndex.per:''
                    }}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="right2">
              <div style="height: 75px">
                <h2 style="font-size: 22px; color: #333">
                  {{ echaerData2.name }}
                </h2>
                <div class="clearfix">
                  <h3 :class="
                    is_(echaerData2)
                      ? 'red_1 green'
                      : 'red_1 red'
                  ">
                    {{ echaerData2.lastIndex?echaerData2.lastIndex.value.toFixed(2):'' }}
                  </h3>
                  <h4 :class="
                    is_(echaerData2)
                      ? 'red_2 green'
                      : 'red_2 red'
                  ">
                    {{
                    is_(echaerData2)
                      ? echaerData2.lastIndex?echaerData2.lastIndex.per:''
                      : echaerData2.lastIndex?"+" + echaerData2.lastIndex.per:''
                  }}
                  </h4>
                  <p><a @click="handle_look_big(3)">查看大图 ></a></p>
                </div>
              </div>
              <div class="right_echar"
                   ref="echar_3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  name:'commodity_index',
  props:['is_study_information'],
  data () {
    return {
      dataList1: [],
      dataList2: [],
      dataList3: [],
      echaerList1: [],
      echaerList2: [],
      echaerList3: [],
      echaerData: {},
      echaerData1: {},
      echaerData2: {},
      idx: 0,
      idx1: 0,
      idx2: 0,
    };
  },
  computed: {
    is_ () { //is_(item) 
      return (data) => data.lastIndex ? data.lastIndex.per.charAt(0) == '-' : ''
    }
  },
  mounted () {
    this.getData();
    this.handle_echar1();
    this.handle_echar2();
    this.handle_echar3();
  },
  methods: {
    handle_look_big (data) {
      if (data === 1) {
        this.$router.push({
          path: "/study_information/commodity_details",
          query: {
            data: JSON.stringify(this.echaerData),
          },
        });
      } else if (data === 2) {
        this.$router.push({
          path: "/study_information/commodity_details",
          query: {
            data: JSON.stringify(this.echaerData1),
          },
        });
      } else if (data === 3) {
        this.$router.push({
          path: "/study_information/commodity_details",
          query: {
            data: JSON.stringify(this.echaerData2),
          },
        });
      }
    },
    handle_index (data, index) {
      this.echaerData = data;
      this.idx = index;
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodity_indices`,
        params: {
          commodityId: data.id,
          days: 30,
        },
      }).then((res) => {
        this.echaerList1 = res.data.data;
        this.handle_echar1();
      });
    },
    handle_index1 (data, index) {
      this.echaerData1 = data;
      this.idx1 = index;
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodity_indices`,
        params: {
          commodityId: data.id,
          days: 30,
        },
      }).then((res) => {
        this.echaerList2 = res.data.data;
        this.handle_echar2();
      });
    },
    handle_index2 (data, index) {
      this.echaerData2 = data;
      this.idx2 = index;
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodity_indices`,
        params: {
          commodityId: data.id,
          days: 30,
        },
      }).then((res) => {
        this.echaerList3 = res.data.data;
        this.handle_echar3();
      });
    },
    getData () {
      // ------------------
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodities`,
        params: {
          type: "1",
        },
      }).then((res) => {
        this.dataList1 = res.data.data;
        this.handle_index(res.data.data[0], 0);
      });
      // ------------------
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodities`,
        params: {
          type: "2",
        },
      }).then((res) => {
        this.dataList2 = res.data.data;
        this.handle_index1(res.data.data[0], 0);
      });
      // ------------------
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodities`,
        params: {
          type: "3",
        },
      }).then((res) => {
        this.dataList3 = res.data.data;
        this.handle_index2(res.data.data[0], 0);
      });
    },
    handle_echar3 () {
      let dataList = this.echaerList3.map((item) => [
        item.publishDate,
        item.value,
      ]);
      var chartDom = this.$refs["echar_3"];
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          left: 45,
          top: 30,
          right: 45,
          bottom: 30,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                return (
                  (params.seriesData.length ? params.seriesData[0].data[0] : params.value.toFixed(2))
                );
              }
            }
          },
          textStyle: {
            align: "left",
          },
          formatter: function (params) {
            var data = params[0]["data"];
            return (
              "<b>" +
              params[0]["seriesName"] +
              "</b><br>交易日期：" +
              moment(data[0]).format("YYYY-MM-DD") +
              "<br>指数点位：" +
              data[1].toFixed(2)
            );
          },
        },
        xAxis: {
          type: 'time',
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: function (value, index) {
              return moment(value).format("YYYYMMDD");
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          scale: true,
          min: function (value) {
            return Math.round(value.min - (value.max - value.min) / 3);
          }
        },
        series: [
          {
            type: "line",
            name: "",
            smooth: false,
            showSymbol: false,
            symbol: "diamond",
            lineStyle: {
              width: 1,
              color: "#1e85f0",
            },
            areaStyle: {
              color: "#deedfb",
            },
            itemStyle: {
              color: "#ff7928",
            },
            data: dataList,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    handle_echar2 () {
      let dataList = this.echaerList2.map((item) => [
        item.publishDate,
        item.value,
      ]);
      var chartDom = this.$refs["echar_2"];
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          left: 45,
          top: 30,
          right: 45,
          bottom: 30,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                return (
                  (params.seriesData.length ? params.seriesData[0].data[0] : params.value.toFixed(2))
                );
              }
            }
          },
          textStyle: {
            align: "left",
          },
          formatter: function (params) {
            var data = params[0]["data"];
            return (
              "<b>" +
              params[0]["seriesName"] +
              "</b><br>交易日期：" +
              moment(data[0]).format("YYYY-MM-DD") +
              "<br>指数点位：" +
              data[1].toFixed(2)
            );
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              return moment(value).format("YYYYMMDD");
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          scale: true,
          min: function (value) {
            return Math.round(value.min - (value.max - value.min) / 3);
          },
        },
        series: [
          {
            type: "line",
            name: "",
            smooth: false,
            showSymbol: false,
            symbol: "diamond",
            lineStyle: {
              width: 1,
              color: "#1e85f0",
            },
            areaStyle: {
              color: "#deedfb",
            },
            itemStyle: {
              color: "#ff7928",
            },
            data: dataList,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    handle_echar1 () {
      let dataList = this.echaerList1.map((item) => [
        item.publishDate,
        item.value,
      ]);
      var chartDom = this.$refs["echar_1"];
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          left: 45,
          top: 30,
          right: 45,
          bottom: 30,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                return (
                  (params.seriesData.length ? params.seriesData[0].data[0] : params.value.toFixed(2))
                );
              }
            }
          },
          textStyle: {
            align: "left",
          },
          formatter: function (params) {
            var data = params[0]["data"];
            return (
              "<b>" +
              params[0]["seriesName"] +
              "</b><br>交易日期：" +
              moment(data[0]).format("YYYY-MM-DD") +
              "<br>指数点位：" +
              data[1].toFixed(2)
            );
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              return moment(value).format("YYYYMMDD");
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          scale: true,
          min: function (value) {
            return Math.round(value.min - (value.max - value.min) / 3);
          },
        },
        series: [
          {
            type: "line",
            name: "",
            smooth: false,
            showSymbol: false,
            symbol: "diamond",
            lineStyle: {
              width: 1,
              color: "#1e85f0",
            },
            areaStyle: {
              color: "#deedfb",
            },
            itemStyle: {
              color: "#ff7928",
            },
            data: dataList,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style scoped>
.right_echar {
  width: 100%;
  flex: 1;
}
.red_1 {
  font-size: 26px;
  float: left;
  margin-top: 10px;
  color: #e83535;
}
.red_2 {
  font-size: 18px;
  float: left;
  margin: 17px 0 0px 10px;
  color: #e83535;
}
.commodity_index_b {
  display: flex;
}
.commodity_index_b .left2 {
  width: 40%;
  padding: 20px;
  height: 475px;
  border-right: 1px solid #e7e7e7;
}
.commodity_index_b .right2 {
  width: 60%;
  padding: 20px;
  height: 475px;
  display: flex;
  flex-direction: column;
}
.left-bg {
  background: #fff;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 30px;
}
.left-bg .l-pd {
  padding: 15px 0 15px 15px;
}
.left-bg h5 {
  border-left: 5px solid #ea4322;
  padding-left: 15px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.rind-bor2 {
  width: 100%;
  background: #e7e7e7;
  height: 1px;
}
.commodity_index_box_item1 {
  width: 100%;
}
.commodity_index_box {
  width: 1200px;
  margin: 0 auto;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.commodity_index {
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: -75px;
  padding-bottom: 75px;
}
* {
  box-sizing: border-box;
}
body,
dd,
dl,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
ol,
p,
select,
td,
textarea,
th,
ul,
li {
  margin: 0;
  padding: 0;
}

body,
html {
  background: #f2f2f2;
  font-family: "微软雅黑", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 180%;
  text-align: left;
  min-width: 1200px;
}

img {
  vertical-align: middle;
  border: none;
  height: auto;
}

div,
ul,
li,
dl,
dt,
dd,
span,
img,
p,
h2,
h3,
h4 {
  list-style: none;
}

a {
  text-decoration: underline;
}

a:link {
  color: #666;
}

a:visited {
  color: #c00;
}

a:hover,
a:active,
a:focus {
  color: #c00;
}

.clear {
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
  *zoom: 1; /*IE触发layout*/
}

.center {
  width: 1200px;
  margin: 0px auto;
}

.red {
  color: #e83535;
}
.green {
  color: #22c36e;
}
.rind-banner {
  /* background: url(../images/res-bg1_02_02.jpg) no-repeat center; */
  width: auto;
  height: 350px;
}
.rind-banner h2 {
  font-size: 50px;
  color: #fff;
  padding: 120px 0 20px 0;
  letter-spacing: 3px;
  font-weight: 900;
}
.rind-banner h3 {
  font-size: 42px;
  color: #fff;
  padding: 20px 0 0px 0;
  letter-spacing: 2px;
  font-weight: 500;
}
.rind-left {
  margin-top: 30px;
  overflow: hidden;
}
.left-bg {
  background: #fff;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.left-bg .l-pd {
  padding: 15px 0 15px 15px;
}
.left-bg .left {
  float: left;
  width: 419px;
  padding: 20px;
  border-right: 1px solid #e7e7e7;
}
.left-bg .left h2 {
  font-size: 22px;
  color: #333;
}
.left-bg .left h3 {
  font-size: 26px;
  float: left;
  margin-top: 10px;
}
.left-bg .left h4 {
  font-size: 18px;
  float: left;
  margin: 12px 0 10px 10px;
}
.left-bg .left p {
  font-size: 14px;
  float: right;
  margin-top: 12px;
}
.left-bg .left p a {
  color: #1b4cb0;
  text-decoration: none;
}
.left-bg .left p a:visited {
  color: #1b4cb0;
  text-decoration: none;
}
.left-bg .left p a:hover {
  color: #ea4322;
  text-decoration: underline;
}
.left-bg .left2 {
  float: left;
  width: 440;
  padding: 20px;
}
.left-bg .left3 {
  float: left;
  width: 195px;
  font-size: 15px;
  padding-left: 10px;
}
.left-bg .left33 {
  float: left;
  width: 130px;
  font-size: 15px;
}
.left-bg .left333 {
  float: left;
  width: 100px;
  font-size: 15px;
}
.left-bg .left4 {
  float: left;
  width: 130px;
  font-size: 18px;
}
.left-bg .left5 {
  float: left;
  width: 100px;
  font-size: 15px;
}
.left-bg .left2 dl dt {
  font-size: 15px;
  color: #999;
  line-height: 40px;
}
.left-bg .left2 dl dd {
  color: #333;
  line-height: 55px;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
}
.left-bg .dd-active {
  background: #f1f5f8;
  font-weight: 600;
}
.left-bg .left6 {
  float: left;
  width: 160px;
  font-size: 15px;
  padding-left: 10px;
}
.left-bg .left66 {
  float: left;
  width: 110px;
  font-size: 15px;
  padding-left: 20px;
}
.left-bg .left666 {
  float: left;
  width: 80px;
  font-size: 15px;
}
.left-bg .left7 {
  float: left;
  width: 110px;
  font-size: 18px;
  padding-left: 20px;
}

.left-bg2 {
  background: #fff;
  width: 840px;
  height: auto;
  margin-bottom: 20px;
  padding: 20px 20px 40px 20px;
  overflow: hidden;
}
.left-bg2 h5 {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}
.left-bg2 h2 {
  font-size: 26px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 5px;
}
.left-bg2 h3 {
  font-size: 26px;
  float: left;
  margin-top: 10px;
}
.left-bg2 h4 {
  font-size: 20px;
  float: left;
  margin: 12px 0 0 10px;
}
.left-bg2 ul {
  margin: 15px 0 15px 0;
}
.left-bg2 ul li {
  float: left;
  width: 80px;
  text-align: center;
  border: 1px solid #476bb4;
  margin-left: -1px;
  color: #4064ad;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.left-bg2 .li-active {
  background: #476bb4;
  color: #fff !important;
}
.left-bg2 h6 {
  font-size: 16px;
  font-weight: 500;
  border-left: 5px solid #3a75ec;
  padding-left: 15px;
  color: #1d5ad7;
}
.left-bg2 .l2-left {
  float: left;
  width: 210px;
  font-size: 14px;
}

.rind-right {
  margin-top: 30px;
  float: right;
  width: 300px;
  background: #fff;
  height: auto;
}
.rind-right .rind-rbg {
  background: #ececec;
}
.rind-right h3 {
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
}
.rind-right .rind-rpd {
  padding: 20px;
}
.rind-right p {
  font-weight: 500;
  color: #666;
  font-size: 13px;
}
.rind-right .rind-rbg2 {
  border-radius: 25px;
  background: #fd6327;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg3 {
  border-radius: 25px;
  background: #ff8b1a;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg4 {
  border-radius: 25px;
  background: #1ac1ff;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg5 {
  border-radius: 25px;
  background: #4e92e7;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-bor {
  width: 100%;
  margin: 20px 0 20px 0;
  height: 1px;
  background: linear-gradient(
    to right,
    #e7e7e7,
    #e7e7e7 3px,
    transparent 3px,
    transparent
  );
  background-size: 5px 100%;
}
.rind-bor2 {
  width: 100%;
  background: #e7e7e7;
  height: 1px;
}
.rind-bor3 {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #ddd,
    #e7e7e7 3px,
    transparent 3px,
    transparent
  );
  background-size: 5px 100%;
}
.rind-bor4 {
  width: 100%;
  background: #e7e7e7;
  height: 1px;
  margin: 15px 0 15px 0;
}
.rind-bor5 {
  width: 100%;
  background: #e7e7e7;
  height: 2px;
  margin: 15px 0 15px 0;
}
.clearfix p {
  float: right;
  margin: 19px 45px 0 0;
}
.clearfix p a {
  color: #1b4cb0;
  text-decoration: none;
  cursor: pointer;
}
.clearfix p a:hover {
  color: #b01b1b;
}
</style>
